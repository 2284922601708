<template>
  <div class="page">
    <img src="../../assets/images/homeBG.png" class="backgroundPic">
    <div class="flexBoxCol">
      <div class="boxHomeLine mbm50">
        <div style="width: 50%;">
          <div class="p24 boxHomeLineTitle">关于我们</div>
          <div class="span16 boxHomeLineText">
            <p>
              湖南中联云达专业从事教育信息化软硬件的研发和销售10多年，拥有专业的软件研发技术团队，具备较强实施和项目运营能力，
              有一定的客户规模和落地运营经验;目前主导项目“斯点智慧校园”综合管理平台已经在多个地区的学校投入使用，
              本项目利用电子学生证+综合管理平台构建智慧校园，主要是为了给学校和家长提供一个沟通的平台，同时也基于人工智能，
              物联网技术和计算机网络的数字化理念融于校园管理进行的统一身份认证、教研、在校综合表现、实时定位、双向通话、
              人脸闸机通行、智能学情分析、考勤、防疫、防校园霸凌、家校沟通、智能相册、校园表现、 优质教育资源共享等系统应用；
              预计未来3年发展学生使用人数达到50万人次。
            </p>
          </div>
        </div>
        <img src="../../assets/images/aboutUs.png" class="homePic">
      </div>
    </div>
    <div class="flexBoxCol mtp50">
      <div class="p48-400 mtp50 mbm50">联系我们</div>
      <div class="homeIconLine mbm50">
        <div class="homeIconBox">
          <svg-icon icon-class="tel"></svg-icon>
          <p class="span16">18974969059</p>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="address"></svg-icon>
          <p class="span16">长沙市雨花区经济开发区振华路工业软件大厦8楼</p>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="email"></svg-icon>
          <p class="span16"> chenqi@hnzlyd.com </p>
        </div>
      </div>
      <baidu-map class="map" :center="mapCenter" :zoom="15">
        <bm-marker :position="mapCenter" :dragging="true" @click="infoWindowOpen">
          <bm-info-window :position="mapCenter" :show="show" @close="infoWindowClose" @open="infoWindowOpen">
            <p class="p20" style="font-weight: 600">湖南中联云达科技有限公司</p>
            <p>湖南省长沙市雨花区智庭院12栋工业软件大厦801-803</p>
            <p>官方热线：18075197982</p>
            <p>官方邮箱： chenqi@hnzlyd.com </p>
          </bm-info-window>
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      casHeight: '500px',
      picList: [
        {
          src: require('../../assets/images/home.png')
        }
      ],
      mapCenter: {
        lng: 113.06496,
        lat: 28.064999
      },
      show: true
    }
  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    }
  }
}
</script>
<style scoped lang="less">
  .homeIconLine {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
  }
  .homeIconBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
    width: 30%;
  }
  .boxHomeLine {
    overflow: hidden;
    display: flex;
    align-items: center;
    background: #F2F3F2;
    padding: 50px 0;
    width: 100%;
    justify-content: space-around;
  }

  .boxHomeLineTitle {
    text-align: left;
    margin-bottom: 50px;
  }
  .boxHomeLineText {
    text-align: left;
    letter-spacing: 0.1em;
    line-height: 30px;
  }
  .homePic {
    width: 400px;
    height: 280px;
  }

  .map {
    width: 80%;
    height: 500px;
  }
  .map /deep/ .anchorBL {
    display: none !important;
  }
</style>
